import cx from 'classnames';
import { useEffect } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { LogBalance } from '../../Users/interfaces';
import { getLanguage } from '../../../utils/language';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { changeHeader } from '../../AppHeader/headerSlice';
import Avatar from '../../../common/components/Avatar/Avatar';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import { formatLocaleHour, formatLongDateWithOffset } from '../../../utils/date';
import { setOnboardingType, setRun, setShouldRun, setStepIndex } from '../../../common/components/Onboarding/OnboardingSlice';
import { bot, logBalanceOrigins, logBalanceTypes, onboardingTypes, paginationHistoryManagement } from '../../../config/commons';

import './HistoryManagement.scss';
import styles from './HistoryManagement.module.scss';
import * as historyManagementSlice from './historyManagementSlice';
import { HistoryManagementFilters } from './HistoryManagementFilters';

const typeBalance = {
  USER: 'USER',
  COMPANY: 'COMPANY',
};

export const HistoryManagement = () => {
  const theme = getCurrentTheme();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const language = getLanguage();

  const historyManagementState = useSelector((state: Store) => state.historyManagement);

  const {
    currentPage,
    data: historyManagement,
    total,
    isLoading,
    filters,
  } = historyManagementState.historyManagement;

  const {
    logBalanceOrigin,
    userIdSelected,
  } = filters;

  const onboardingUserData = useSelector((state: Store) => state.onboarding.user);
  const existsUserData = useSelector((state: Store) => state.onboarding.existsUserData);

  useEffect(() => {
    if (existsUserData && !onboardingUserData.onboarding?.administrationHistory) {
      setTimeout(() => {
        dispatch(setStepIndex(0));
        dispatch(setOnboardingType(onboardingTypes.ADMINISTRATION_HISTORY));
        dispatch(setShouldRun(true));
        dispatch(setRun(true));
      }, 500);
    }
  }, [dispatch, onboardingUserData, existsUserData]);

  useEffect(() => {
    dispatch(changeHeader({ title: 'historyManagement.title' }));
    dispatch(historyManagementSlice.fetchHistoryManagement({ page: 0 }));
    dispatch(historyManagementSlice.fetchUsers());
  }, [dispatch]);

  const onChangePage = (page: number) => {
    dispatch(historyManagementSlice.setCurrentPageHistory(page));

    if (logBalanceOrigin === logBalanceOrigins.ALL) {
      dispatch(historyManagementSlice.fetchHistoryManagement({ page: page, userId: userIdSelected }));
    } else {
      dispatch(historyManagementSlice.fetchHistoryManagement({ page: page, origin: logBalanceOrigin, userId: userIdSelected }));
    }
  };

  function getTextCreditedUser (analysis: number | undefined, name: string) {
    let element;

    switch (language) {
      case 'es':
        element = <div className={styles.name}>
          {t('historyManagement.actions.balanceManagement.accreditUser1')}
          <span>{analysis}</span>
          {t('historyManagement.actions.balanceManagement.accreditUser2')}
          <span>{name}</span>
        </div>;
        break;

      default:
        element = <div className={styles.name}>
          <span>{analysis}</span>
          {t('historyManagement.actions.balanceManagement.accreditUser1')}
          <span>{name}</span>
        </div>;
        break;
    }

    return element;
  }

  function getTextDebitedUser (analysis: number | undefined, name: string) {
    let element;

    switch (language) {
      case 'es':
        element = <div className={styles.name}>
          {t('historyManagement.actions.balanceManagement.debitUser1')}
          <span>{analysis}</span>
          {t('historyManagement.actions.balanceManagement.debitUser2')}
          <span>{name}</span>
        </div>;
        break;

      default:
        element = <div className={styles.name}>
          <span>{analysis}</span>
          {t('historyManagement.actions.balanceManagement.debitUser1')}
          <span>{name}</span>
        </div>;
        break;
    }

    return element;
  }

  function getTextCreditedCompany (analysis: number | undefined, name: string) {
    let element;

    switch (language) {
      case 'es':
        element = <div className={styles.name}>
          {t('historyManagement.actions.balanceManagement.accreditCompany1')}
          <span>{analysis}</span>
          {t('historyManagement.actions.balanceManagement.accreditCompany2')}
          <span>{name}</span>
        </div>;
        break;

      default:
        element = <div className={styles.name}>
          <span>{analysis}</span>
          {t('historyManagement.actions.balanceManagement.accreditCompany1')}
          <span>{name}</span>
        </div>;
        break;
    }

    return element;
  }

  function getTextDebitedCompany (analysis: number | undefined, name: string) {
    let element;

    switch (language) {
      case 'es':
        element = <div className={styles.name}>
          {t('historyManagement.actions.balanceManagement.debitCompany1')}
          <span>{analysis}</span>
          {t('historyManagement.actions.balanceManagement.debitCompany2')}
          <span>{name}</span>
        </div>;
        break;

      default:
        element = <div className={styles.name}>
          <span>{analysis}</span>
          {t('historyManagement.actions.balanceManagement.debitCompany1')}
          <span>{name}</span>
        </div>;
        break;
    }

    return element;
  }

  const getBalanceManagementMessage = (name: string, analysis?: number, quotaType?: string, typeCollection?: string) => {
    if (typeCollection === typeBalance.USER) {
      if (quotaType === logBalanceTypes.ACCREDITATION) {
        return getTextCreditedUser(analysis, name);
      }

      return getTextDebitedUser(analysis, name);
    }

    if (quotaType === logBalanceTypes.ACCREDITATION) {
      return getTextCreditedCompany(analysis, name);
    }

    return getTextDebitedCompany(analysis, name);
  };

  function getAction (typeAction: string, name: string, analysis?: number, quotaType?: string, typeCollection?: string) {
    let message = undefined;

    switch (typeAction) {
      case logBalanceOrigins.MONTHLY_RELOAD:
        message = <div className={styles.name}>
          {t('historyManagement.actions.monthlyReload1')}
          <span>{name}</span>
          {t('historyManagement.actions.monthlyReload2')}
        </div>;
        break;

      case logBalanceOrigins.COMPANY_CREATION:
        message = <div className={styles.name}>
          {t('historyManagement.actions.companyCreation1')}
          <span>{name}</span>
          {t('historyManagement.actions.companyCreation2')}
        </div>;
        break;

      case logBalanceOrigins.USER_CREATION:
        message = <div className={styles.name}>
          {t('historyManagement.actions.userCreation1')}
          <span>{name}</span>
          {t('historyManagement.actions.userCreation2')}
        </div>;
        break;

      case logBalanceOrigins.BALANCE_MANAGEMENT:
        message = getBalanceManagementMessage(name, analysis, quotaType, typeCollection);
        break;

      case logBalanceOrigins.USER_INACTIVATION:
        message = <div className={styles.name}>
          {t('historyManagement.actions.userInactivation')}
          <span>{name}</span>
        </div>;
        break;
    }

    return message;
  }

  function getIdContainer (record: LogBalance) {
    const typeAction = record.origin;
    const executor = `executor_${record.creatorUserId?._id}`;
    const receiver = `receiver_${record.targetUserId?._id}`;
    let id;

    switch (typeAction) {
      case logBalanceOrigins.MONTHLY_RELOAD:
        id = typeAction.toLowerCase() + '_' + receiver;
        break;

      case logBalanceOrigins.COMPANY_CREATION:
        id = typeAction.toLowerCase();
        break;

      case logBalanceOrigins.USER_CREATION:
        id = typeAction.toLowerCase() + '_' + executor + '_' + receiver;
        break;

      case logBalanceOrigins.BALANCE_MANAGEMENT:
        id = typeAction.toLowerCase() + '_' + executor + '_' + receiver;
        break;

      case logBalanceOrigins.USER_INACTIVATION:
        id = typeAction.toLowerCase() + '_' + executor + '_' + receiver;
        break;
    }

    return id;
  }

  function getIcon (typeAction: string, quotaType?: string) {
    let icon;
    switch (typeAction) {
      case logBalanceOrigins.MONTHLY_RELOAD:
        icon = <Icon name='refresh' className={styles.resetBalanceIcon} />;
        break;

      case logBalanceOrigins.COMPANY_CREATION:
        icon = <Icon name='building' className={styles.addCompanyIcon} />;
        break;

      case logBalanceOrigins.USER_CREATION:
        icon = <Icon name='user-add' className={styles.addUserIcon} />;
        break;

      case logBalanceOrigins.BALANCE_MANAGEMENT:
        if (quotaType === logBalanceTypes.ACCREDITATION) {

          icon = <Icon name='add-circle' className={styles.accreditationIcon} />;
        } else {
          icon = <Icon name='indeterminate-circle' className={styles.debitIcon} />;

        }
        break;

      case logBalanceOrigins.USER_INACTIVATION:
        icon = <Icon name='user-unfollow' className={styles.userInactivationIcon} />;
        break;
    }

    return icon;
  }

  const columns: ColumnProps<LogBalance>[] = [
    {
      className: 'action',
      key: 1,
      title: t('historyManagement.action'),
      width: '35%',
      render: (_, record: LogBalance) => {
        if (record.origin) {
          let name = record.targetUserId?.name;
          const typeCollection = record.targetUserId ? typeBalance.USER : typeBalance.COMPANY;

          if (!name) {
            name = record.targetCompanyId?.name;
          }

          const id = getIdContainer(record);
          return (
            <div id={id} className={styles.row}>
              {getIcon(record.origin, record.quotaType)}
              <div className={styles.name}> {getAction(record.origin, name, record.quotaAmount, record.quotaType, typeCollection)} </div>
            </div>
          );
        }
        return null;
      },
    },
    {
      key: 2,
      title: t('historyManagement.executor'),
      width: '20%',
      responsive: ['md'] as Breakpoint[],
      render: (_, record: LogBalance) => {
        let isSuperAdmin;
        let firstName;
        let lastName;
        let nameUser;
        let creatorUserId;

        if (!(record.impersonateId || record.creatorUserId)) {
          return null;
        }

        if (record.impersonateId) {
          isSuperAdmin = record.impersonateId.isSuperAdmin;
          firstName = record.impersonateId.firstName;
          lastName = record.impersonateId.lastName;
          nameUser = record.impersonateId.name;
          creatorUserId = record.impersonateId._id;

        } else {
          isSuperAdmin = record.creatorUserId.isSuperAdmin;
          firstName = record.creatorUserId.firstName;
          lastName = record.creatorUserId.lastName;
          nameUser = record.creatorUserId.name;
          creatorUserId = record.creatorUserId._id;

          if (!record.creatorUserId._id) {
            nameUser = bot.fullName;
            lastName = bot.lastName;
          }
        }

        const showLine = userIdSelected && creatorUserId && creatorUserId === userIdSelected;

        return (
          <div className={styles.row}>
            <Avatar
              firstName={firstName}
              lastName={lastName}
              className={isSuperAdmin ? styles.avatar : ''}
            />
            <div className={cx(styles.name, showLine ? styles.underline : '')}> {nameUser} </div>
          </div>
        );
      },
    },
    {
      key: 3,
      width: '2%',
      align: 'center',
      className: styles.tableCell,
      render: (_, record: LogBalance) => {
        if (record.targetUserId) {
          return <Icon name='arrow-right-s' theme={theme} />;
        }
        // if return null, table will display an "-" in the empty cell
        return <div></div>;
      },
    },
    {
      key: 4,
      title: t('historyManagement.receiver'),
      width: '20%',
      responsive: ['sm'] as Breakpoint[],
      render: (_, record: LogBalance) => {
        if (record.targetUserId) {
          const showLine = userIdSelected && record.targetUserId._id && record.targetUserId._id === userIdSelected;
          return (
            <div className={styles.row}>
              <Avatar firstName={record.targetUserId.firstName} lastName={record.targetUserId.lastName} />
              <div className={cx(styles.name, showLine ? styles.underline : '')}> {record.targetUserId.name} </div>
            </div>
          );
        }
        return null;
      },
    },
    {
      key: 5,
      title: t('historyManagement.date'),
      width: '18%',
      responsive: ['lg'] as Breakpoint[],
      render: (_, record: LogBalance) => {
        const createdAt = formatLongDateWithOffset(record.createdAt) + ' ' + formatLocaleHour(record.createdAt);
        return createdAt;
      }
    },
  ];

  return (
    <div className={cx(styles.container, 'historicalBalanceTable')}>
      <HistoryManagementFilters theme={theme} />

      <LrvTable
        columns={columns}
        className={styles.table}
        dataSource={historyManagement}
        loading={isLoading}
        scroll={{ y: '' }}
        size='small'
        theme={theme}
        pagination={{
          size: 'default',
          defaultPageSize: paginationHistoryManagement,
          pageSize: paginationHistoryManagement,
          total: total,
          onChange: onChangePage,
          showSizeChanger: false,
          current: currentPage
        }}
      />
    </div>
  );
};
