import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { StockingParameter } from '../interfaces';
import { getBodyStockingParameter } from '../helpers';
import { Store } from '../../../state/store.interfaces';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';

import { StockingParameterForm } from './StockingParameterForm';
import { StockingParameterSelected } from './StockingParameterSelected';
import * as detailStockingParameterSlice from './detailStockingParameterSlice';

export const StockingParameterDetailScreen = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const { company } = useSelector((state: Store) => state.header);
  const { selectedStocking } = useSelector((state: Store) => state.stockings);
  const {
    showEditStockingParameterModal,
    stockingParameterSelected,
    companyStockingParameters,
    isLoadingEditStockingParameter,
  } = useSelector((state: Store) => state.editStockingParameter);

  const [isEditing, setIsEditing] = useState(false);
  const [enterParameters, setEnterParameters] = useState(false);
  const [stockingParameters, setStockingParameters] = useState<StockingParameter[]>([]);
  const [auxiliarStockingParameters, setAuxiliarStockingParameters] = useState<StockingParameter[]>([]);

  useEffect(() => {
    if (stockingParameterSelected.data.length === 0 || companyStockingParameters.length === 0) {
      return;
    }

    const parameters: StockingParameter[] = [];
    for (let index = 0; index < stockingParameterSelected.data.length; index++) {
      const currentCompanyStockingParameter = companyStockingParameters.find(companyStockingParameter => companyStockingParameter._id === stockingParameterSelected.data[index].key);

      if (!currentCompanyStockingParameter?.key) {
        continue;
      }

      const item: StockingParameter = {
        _id: currentCompanyStockingParameter._id,
        key: currentCompanyStockingParameter.key,
        type: currentCompanyStockingParameter.type,
        value: stockingParameterSelected.data[index].value,
        frequency: stockingParameterSelected.data[index].frequency,
        options: currentCompanyStockingParameter.options,
        max: currentCompanyStockingParameter.max,
        min: currentCompanyStockingParameter.min,
      };

      parameters.push(item);
    }

    setStockingParameters(parameters);
  }, [stockingParameterSelected.data, companyStockingParameters.length, showEditStockingParameterModal]);

  const resetForm = () => {
    setStockingParameters([]);
    setEnterParameters(false);
    setAuxiliarStockingParameters([]);

    dispatch(detailStockingParameterSlice.setShowEditStockingParameterModal(false));
    dispatch(detailStockingParameterSlice.setCompanyStockingParameters([]));
  };

  const isValidForm = () => {
    return !enterParameters;
  };

  const renderBody = () => {
    if (enterParameters) {
      return (
        <StockingParameterForm
          setStockingParameters={setStockingParameters}
          stockingParameters={stockingParameters}
          setEnterParameters={setEnterParameters}
          auxiliarStockingParameters={auxiliarStockingParameters}
          setAuxiliarStockingParameters={setAuxiliarStockingParameters}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      );
    }

    return (
      <StockingParameterSelected
        stockingParameters={stockingParameters}
        setStockingParameters={setStockingParameters}
        setEnterParameters={setEnterParameters}
        setAuxiliarStockingParameters={setAuxiliarStockingParameters}
        setIsEditing={setIsEditing}
        currentDate={stockingParameterSelected.date}
      />
    );
  };

  const getSubmitText = () => {
    return stockingParameters.length > 0 ? t('stockingParameter.form.accept') : t('stockingParameter.form.remove');
  };

  return (
    <LrvModal
      theme='light'
      title={t('stockingParameter.formNew.title')}
      open={showEditStockingParameterModal}
      destroyOnClose={true}
      okButtonProps={{ id: 'stocking_parameter', htmlType: 'submit', form: 'formStockingParameter', loading: isLoadingEditStockingParameter, disabled: !isValidForm() }}
      onOk={() => {
        const body = getBodyStockingParameter({ _id: stockingParameterSelected._id, parameterDate: stockingParameterSelected.date, selectedStocking, stockingParameters, companyId: company._id, active: stockingParameters.length > 0 });
        dispatch(detailStockingParameterSlice.editStockingParameters({ body, onCloseForm: resetForm }));
      }}
      okText={getSubmitText()}
      cancelText={t('stockingParameter.form.cancel')}
      onCancel={resetForm}
    >
      {renderBody()}
    </LrvModal>
  );
};
