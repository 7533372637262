import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getUserSession } from '../../../utils/userSession';
import { UserSession } from '../../../common/interfaces/auth';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { COMPANY_STOCKING_PARAMETER_URL, GLOBAL_STOCKING_PARAMETER_URL } from '../../../config/config.api';

import { CompanyStockingParameterState, GlobalStockingParameter, CompanyStockingParameter, CompanyStockingParameterBody } from './interfaces';

const initialState: CompanyStockingParameterState = {
  globalStockingParameters: [],
  companyStockingParameters: [],
  isLoadingGlobalStockingParameter: false,
  isLoadingCompanyStockingParameter: false,
  showCreateParameterModal: false,
  showEditParameterModal: false,
  isCreatingCompanyStockingParameterModal: false,
};

export const stockingParameterSlice = createSlice({
  name: 'companyStockingParameter',
  initialState,
  reducers: {
    setGlobalStockingParameters: (state: CompanyStockingParameterState, action: PayloadAction<GlobalStockingParameter[]>) => {
      state.globalStockingParameters = action.payload;
    },
    setCompanyStockingParameters: (state: CompanyStockingParameterState, action: PayloadAction<CompanyStockingParameter[]>) => {
      state.companyStockingParameters = action.payload;
    },
    setShowCreateParameterModal: (state: CompanyStockingParameterState, action: PayloadAction<boolean>) => {
      state.showCreateParameterModal = action.payload;
    },
    setShowEditParameterModal: (state: CompanyStockingParameterState, action: PayloadAction<boolean>) => {
      state.showEditParameterModal = action.payload;
    },
    setIsCreatingCompanyStockingParameterModal: (state: CompanyStockingParameterState, action: PayloadAction<boolean>) => {
      state.isCreatingCompanyStockingParameterModal = action.payload;
    },
    setIsLoadingCompanyStockingParameter: (state: CompanyStockingParameterState, action: PayloadAction<boolean>) => {
      state.isLoadingCompanyStockingParameter = action.payload;
    },
    setIsLoadingGlobalStockingParameter: (state: CompanyStockingParameterState, action: PayloadAction<boolean>) => {
      state.isLoadingGlobalStockingParameter = action.payload;
    },
    setCompanyStockingParameter: (state: CompanyStockingParameterState, action: PayloadAction<CompanyStockingParameter>) => {
      state.companyStockingParameter = action.payload;
    },
  },
});

export const {
  setGlobalStockingParameters,
  setCompanyStockingParameters,
  setShowCreateParameterModal,
  setShowEditParameterModal,
  setIsCreatingCompanyStockingParameterModal,
  setIsLoadingCompanyStockingParameter,
  setIsLoadingGlobalStockingParameter,
  setCompanyStockingParameter,
} = stockingParameterSlice.actions;

export const fetchGlobalStockingParameter = () => async (dispatch: Function) => {
  const params = {
    $limit: -1,
  };

  dispatch(setIsLoadingGlobalStockingParameter(true));

  try {
    const response = await axios.get<GlobalStockingParameter[]>(GLOBAL_STOCKING_PARAMETER_URL, { params });
    dispatch(setGlobalStockingParameters(response.data));
    dispatch(setIsLoadingGlobalStockingParameter(false));
  } catch (e) {
    console.log(e?.response);
  }

};

export const fetchCompanyStockingParameter = () => async (dispatch: Function) => {
  const userSession: UserSession = getUserSession();

  const params = {
    active: true,
    companyId: userSession.companyId,
    $limit: -1,
  };

  dispatch(setIsLoadingCompanyStockingParameter(true));

  try {
    const response = await axios.get<CompanyStockingParameter[]>(COMPANY_STOCKING_PARAMETER_URL, { params });
    dispatch(setCompanyStockingParameters(response.data));
    dispatch(setIsLoadingCompanyStockingParameter(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const createCompanyStockingParameter = (props: { body: CompanyStockingParameterBody; onSuccess: () => void; }) => async (dispatch: Function) => {
  const { body, onSuccess } = props;
  dispatch(setIsCreatingCompanyStockingParameterModal(true));

  try {
    await axios.post(COMPANY_STOCKING_PARAMETER_URL, body);
    dispatch(fetchCompanyStockingParameter());
    onSuccess();
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsCreatingCompanyStockingParameterModal(false));
};

export const editCompanyStockingParameter = (props: { id: string; body: CompanyStockingParameterBody; onSuccess: () => void; }) => async (dispatch: Function) => {
  const { id, body, onSuccess } = props;
  dispatch(setIsCreatingCompanyStockingParameterModal(true));

  const url = `${COMPANY_STOCKING_PARAMETER_URL}/${id}`;

  try {
    await axios.patch(url, body);
    dispatch(fetchCompanyStockingParameter());
    onSuccess();
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsCreatingCompanyStockingParameterModal(false));
};

export default stockingParameterSlice.reducer;
