import moment from 'moment';
import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { axiosClient as axios } from '../../utils/axios_instance';
import * as stockingParameterSlice from '../Analysis/stockingParameterSlice';
import { openSuccessNotification } from '../../common/notification/Notification';
import { CompanyStockingParameter } from '../Company/StockingParameters/interfaces';
import { COMPANY_STOCKING_PARAMETER_URL, STOCKING_PARAMETER_URL } from '../../config/config.api';

import { showStockingParameterError } from './helpers';
import { NewStockingParameter, NewStockingParameterState } from './interfaces';
import { StockingParameterData } from './Detail/interfaces';

const initialState: NewStockingParameterState = {
  companyStockingParameters: [],
  lastFourDaysParameters: [],
  isLoadingFourDaysParameters: false,
  isLoadingNewStockingParameters: false,
  showStockingParameterModal: false,
  parameterDate: moment().toISOString(),
};

export const newStockingParameterSlice = createSlice({
  name: 'newStockingParameter',
  initialState,
  reducers: {
    setCompanyStockingParameters: (state: NewStockingParameterState, action: PayloadAction<CompanyStockingParameter[]>) => {
      state.companyStockingParameters = action.payload;
    },
    setLastFourDaysParameters: (state: NewStockingParameterState, action: PayloadAction<StockingParameterData[]>) => {
      state.lastFourDaysParameters = action.payload;
    },
    setIsLoadingNewStockingParameters: (state: NewStockingParameterState, action: PayloadAction<boolean>) => {
      state.isLoadingNewStockingParameters = action.payload;
    },
    setIsLoadingFourDaysParameters: (state: NewStockingParameterState, action: PayloadAction<boolean>) => {
      state.isLoadingFourDaysParameters = action.payload;
    },
    setShowStockingParameterModal: (state: NewStockingParameterState, action: PayloadAction<boolean>) => {
      state.showStockingParameterModal = action.payload;
    },
    setParameterDate: (state: NewStockingParameterState, action: PayloadAction<string>) => {
      state.parameterDate = action.payload;
    },
  },
});

export const {
  setLastFourDaysParameters,
  setCompanyStockingParameters,
  setIsLoadingFourDaysParameters,
  setIsLoadingNewStockingParameters,
  setShowStockingParameterModal,
  setParameterDate,
} = newStockingParameterSlice.actions;

export const fetchLastFourDaysParameters = (props: { stockingId: string; companyId: string }) => async (dispatch: Function) => {
  const { stockingId, companyId } = props;
  dispatch(setIsLoadingFourDaysParameters(true));

  const minDate = moment().subtract(3, 'd').startOf('day').toISOString();
  const params = {
    $limit: -1,
    'date[$gte]': minDate,
    companyId,
    stockingId,
  };

  try {
    const response = await axios.get<StockingParameterData[]>(STOCKING_PARAMETER_URL, { params: params });
    dispatch(setLastFourDaysParameters(response.data));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsLoadingFourDaysParameters(false));
};

export const fetchCompanyStockingParameters = (props: { companyId: string }) => async (dispatch: Function) => {
  const { companyId } = props;

  const params = {
    active: true,
    $limit: -1,
    companyId: companyId,
  };

  try {
    const response = await axios.get<CompanyStockingParameter[]>(COMPANY_STOCKING_PARAMETER_URL, { params });
    dispatch(setCompanyStockingParameters(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const createStockingParameters = (props: { body: NewStockingParameter; onCloseForm: Function }) => async (dispatch: Function) => {
  const { body, onCloseForm } = props;

  dispatch(setIsLoadingNewStockingParameters(true));

  try {
    await axios.post(STOCKING_PARAMETER_URL, body);
    dispatch(setShowStockingParameterModal(false));
    openSuccessNotification(i18next.t('stockingParameter.formNew.success'));
    onCloseForm();
    dispatch(setIsLoadingNewStockingParameters(false));
  } catch (e) {
    console.log(e?.response);
    dispatch(setIsLoadingNewStockingParameters(false));

    if (e?.response?.data?.data?.error) {
      showStockingParameterError({ error: e });
    }

    return;
  }

  dispatch(stockingParameterSlice.fetchStockingParameters({ page: 0, stockingId: body.stockingId, companyId: body.companyId }));
};

export const showStockingParameterModal = (props: { companyId: string }) => (dispatch: Function) => {
  const { companyId } = props;

  dispatch(fetchCompanyStockingParameters({ companyId }));
  dispatch(setShowStockingParameterModal(true));
};

export default newStockingParameterSlice.reducer;
