import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StockingParameter } from '../interfaces';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { formatLongDateWithOffset } from '../../../utils/date';
import { LrvForm } from '../../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import IconButton from '../../../common/components/buttons/IconButton';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import ActionButton from '../../../common/components/buttons/ActionButton';
import { disabledStartButton, subtractStockingParameters } from '../helpers';
import { existsRepeatedParameters } from '../../../helpers/stocking-parameter';
import { CompanyStockingParameter } from '../../Company/StockingParameters/interfaces';
import { getParameterLabelByKey } from '../../Company/StockingParameters/helpers';

import styles from './StockingParameterSelected.module.scss';
import * as detailStockingParameterSlice from './detailStockingParameterSlice';

interface Props {
  stockingParameters: StockingParameter[];
  setStockingParameters: Dispatch<SetStateAction<StockingParameter[]>>;
  setEnterParameters: Dispatch<SetStateAction<boolean>>;
  setAuxiliarStockingParameters: Dispatch<SetStateAction<StockingParameter[]>>;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  currentDate: string;
}

export const StockingParameterSelected = (props: Props) => {
  const {
    stockingParameters,
    setStockingParameters,
    setEnterParameters,
    setAuxiliarStockingParameters,
    setIsEditing,
    currentDate,
  } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { companyStockingParameters } = useSelector((state: Store) => state.editStockingParameter);
  const subtractedStockingParameters = subtractStockingParameters({ companyStockingParameters, stockingParameters });

  const columns: ColumnsType<CompanyStockingParameter> = [
    {
      key: 1,
      title: t('stockingParameter.parameter'),
      dataIndex: 'key',
      width: '30%',
      render: (key) => getParameterLabelByKey(key),
    },
    {
      key: 2,
      title: t('stockingParameter.value'),
      dataIndex: 'value',
      width: '25%',
    },
    {
      key: 3,
      title: t('stockingParameter.form.frequency'),
      dataIndex: 'frequency',
      width: '25%',
    },
    {
      key: 4,
      title: '',
      dataIndex: 'delete',
      width: '8%',
      render: (_, record, index: number) => {
        return (
          <IconButton
            theme='light'
            className={styles.buttonDelete}
            icon={<Icon name='delete-bin' type='line' theme='light' className={styles.icon} />}
            onClick={() => {
              const stockingParametersCopy: StockingParameter[] = cloneDeep(stockingParameters);
              stockingParametersCopy.splice(index, 1);
              setStockingParameters(stockingParametersCopy);
            }}
          />
        );
      },
    },
  ];

  const getStylesItem = (companyStockingParameter: CompanyStockingParameter) => {
    if (companyStockingParameter.selected) {
      return cx(styles.gridItem, styles.itemSelected);
    }

    return styles.gridItem;
  };

  const renderItem = (props: { companyStockingParameter: CompanyStockingParameter; index: number; }) => {
    const { companyStockingParameter, index } = props;

    return (
      <div
        key={`${companyStockingParameter.key}${index}`}
        className={getStylesItem(companyStockingParameter)}
        onClick={() => {
          const companyStockingParametersCopy: CompanyStockingParameter[] = cloneDeep(companyStockingParameters);
          companyStockingParametersCopy[index].selected = !companyStockingParameter.selected;

          dispatch(detailStockingParameterSlice.setCompanyStockingParameters(companyStockingParametersCopy));
        }}
      >
        <LrvText text={getParameterLabelByKey(companyStockingParameter.key)} theme='light' />
      </div>
    );
  };

  const renderStartButton = () => {
    if (subtractedStockingParameters.length === 0) {
      return null;
    }

    return (
      <div
        className={styles.containerStartButton}
      >
        <ActionButton
          id='btn_start_stocking'
          theme='light'
          type='primary'
          disabled={disabledStartButton(subtractedStockingParameters)}
          onClick={() => {
            setEnterParameters(true);
            const selectedItems = subtractedStockingParameters.filter((parameter) => !!parameter.selected);
            const newStockingParameters: StockingParameter[] = [];

            for (let index = 0; index < selectedItems.length; index++) {
              const parameter = selectedItems[index];

              const item: StockingParameter = {
                _id: parameter._id,
                key: parameter.key,
                type: parameter.type,
                value: '',
                frequency: parameter.frequency,
                options: parameter.options,
                max: parameter.max,
                min: parameter.min,
              };

              newStockingParameters.push(item);
            }

            setAuxiliarStockingParameters(newStockingParameters);
          }}
        >
          {t('stockingParameter.formNew.start')}
        </ActionButton>
      </div>
    );
  };

  const renderEditButton = () => {
    return (
      <div
        className={styles.containerStartButton}
      >
        <ActionButton
          id='btn_edit_stocking'
          theme='light'
          type='primary'
          disabled={stockingParameters.length === 0}
          onClick={() => {
            setEnterParameters(true);
            setIsEditing(true);
            const newStockingParameters: StockingParameter[] = [];

            for (let index = 0; index < stockingParameters.length; index++) {
              const parameter = stockingParameters[index];

              const item: StockingParameter = {
                _id: parameter._id,
                key: parameter.key,
                type: parameter.type,
                value: parameter.value,
                frequency: parameter.frequency,
                options: parameter.options,
                max: parameter.max,
                min: parameter.min,
              };

              newStockingParameters.push(item);
            }

            setAuxiliarStockingParameters(newStockingParameters);
          }}
        >
          {t('stockingParameter.formNew.edit')}
        </ActionButton>
      </div>
    );
  };

  const renderDescripcionLabel = () => {
    if (subtractedStockingParameters.length === 0) {
      return null;
    }

    return (
      <div className={styles.title}>
        <LrvText text={t('stockingParameter.formNew.description')} theme='light' />
      </div>
    );
  };

  const renderStockingParameters = () => {
    if (subtractedStockingParameters.length === 0) {
      return null;
    }

    return (
      <div className={styles.gridContainer}>
        {companyStockingParameters.map((companyStockingParameter, index) => {
          if (!companyStockingParameter.active) {
            return null;
          }

          const subtractedStockingParameter = subtractedStockingParameters.find((subtractedStockingParameter) => subtractedStockingParameter._id === companyStockingParameter._id);
          if (!subtractedStockingParameter) {
            return null;
          }

          return renderItem({ companyStockingParameter: subtractedStockingParameter, index });
        })}
      </div>
    );
  };

  return (
    <LrvForm
      className={styles.stockingParameterSelected}
      theme='light'
      layout='vertical'
    >
      {renderDescripcionLabel()}
      {renderStockingParameters()}
      {renderStartButton()}

      {
        stockingParameters.length > 0 &&
        <>
          <div className={styles.currentDate}>
            <LrvText text={formatLongDateWithOffset(currentDate)} theme='light' />
          </div>

          <LrvTable
            className={styles.table}
            columns={columns}
            dataSource={stockingParameters}
            scroll={{ y: '' }}
            theme='light'
            size='small'
            pagination={false}
            rowKey={(record: CompanyStockingParameter) => record.frequency ? record.key + record.frequency : record.key}
            rowClassName={(record: StockingParameter, index: number) => {
              if (existsRepeatedParameters({ index, selectedStockingParameter: record, stockingParameters })) {
                return styles.repeatedParameter;
              }

              return '';
            }}
          />

          {renderEditButton()}
        </>
      }

    </LrvForm>
  );
};
