import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { paginationAnalysis } from '../../config/commons';
import { axiosClient as axios } from '../../utils/axios_instance';
import { openSuccessNotification } from '../../common/notification/Notification';
import { CompanyStockingParameter } from '../Company/StockingParameters/interfaces';
import { ANALYSES_URL, STOCKING_PARAMETER_URL, STOCKING_PARAMETER_CUSTOMIZABLE_URL, COMPANY_STOCKING_PARAMETER_URL } from '../../config/config.api';

import { StockingParameterCustom, StockingParameterPayload, StockingParameterState } from './interfaces';

const initialState: StockingParameterState = {
  isLoading: false,
  data: [],
  limit: 0,
  skip: 0,
  total: 0,
  currentPage: 1,
  isRefreshing: false,
  isLoadingPage: false,
  isCreatingStockingParameter: false,
  isEditingStockingParameter: false,
  stockingParameterCustom: {
    _id: '',
    fields: [],
    companyId: '',
  },
  companyStockingParameters: [],
};

export const stockingParameterSlice = createSlice({
  name: 'stockingParameter',
  initialState,
  reducers: {
    setStockingParameters: (state: StockingParameterState, action: PayloadAction<StockingParameterPayload>) => {
      state.data = action.payload.data;
      state.limit = action.payload.limit;
      state.skip = action.payload.skip;
      state.total = action.payload.total;
    },
    resetStockingParameters: (state: StockingParameterState) => {
      state.data = [];
      state.limit = 0;
      state.skip = 0;
      state.total = 0;
    },
    setIsLoading: (state: StockingParameterState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsRefreshing: (state: StockingParameterState, action: PayloadAction<boolean>) => {
      state.isRefreshing = action.payload;
    },
    setIsLoadingPage: (state: StockingParameterState, action: PayloadAction<boolean>) => {
      state.isLoadingPage = action.payload;
    },
    setIsCreatingStockingParameter: (state: StockingParameterState, action: PayloadAction<boolean>) => {
      state.isCreatingStockingParameter = action.payload;
    },
    setIsEditingStockingParameter: (state: StockingParameterState, action: PayloadAction<boolean>) => {
      state.isEditingStockingParameter = action.payload;
    },

    setStockingParameterCustom: (state: StockingParameterState, action: PayloadAction<StockingParameterCustom>) => {
      state.stockingParameterCustom = action.payload;
    },
    setCurrentPage: (state: StockingParameterState, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },

    setCompanyStockingParameters: (state: StockingParameterState, action: PayloadAction<CompanyStockingParameter[]>) => {
      state.companyStockingParameters = action.payload;
    },
  },
});

export const {
  setStockingParameters,
  resetStockingParameters,
  setIsLoading,
  setIsRefreshing,
  setIsLoadingPage,
  setIsCreatingStockingParameter,
  setIsEditingStockingParameter,
  setStockingParameterCustom,
  setCurrentPage,
  setCompanyStockingParameters,
} = stockingParameterSlice.actions;

export const fetchStockingParameters = (props: { stockingId: string; page: number; companyId?: string }) => async (dispatch: Function) => {
  const { stockingId, page, companyId } = props;

  let skip = 0;
  dispatch(setIsLoading(true));

  if (page !== 0) {
    skip = paginationAnalysis * (page - 1);
  }

  const params = {
    $limit: paginationAnalysis,
    $skip: skip,
    active: true,
    companyId,
    stockingId,
    '$sort[date]': -1,
  };

  try {
    const response = await axios.get<StockingParameterPayload>(STOCKING_PARAMETER_URL, { params: params });
    dispatch(setStockingParameters(response.data));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsLoading(false));
};

export const updateAnalysis = (
  props: {
    companyId: string,
    analysisId: string,
    stockingId: string,
    analysisData: {
      code: string; inputData: { stage: number; sampleWeight: number; maturationId: string; species: string }; fix: boolean;
    },
    currentPage: number,
  }
) => async (dispatch: Function) => {
  const { companyId, analysisId, stockingId, analysisData, currentPage } = props;

  try {
    await axios.patch(`${ANALYSES_URL}/${analysisId}`, analysisData);
    openSuccessNotification(i18next.t('analysis.updated'));
    dispatch(fetchStockingParameters({ stockingId, page: currentPage, companyId }));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchStockingParameterCustom = (props: { companyId: string }) => async (dispatch: Function) => {
  const { companyId } = props;

  const params = {
    $limit: -1,
    companyId,
    $select: ['_id', 'companyId', 'fields'],
  };

  const response = await axios.get<StockingParameterCustom[]>(STOCKING_PARAMETER_CUSTOMIZABLE_URL, { params: params });
  if (!response.data.length) {
    return;
  }

  dispatch(setStockingParameterCustom(response.data[0]));
};

export const fetchCompanyStockingParameter = (props: { companyId: string }) => async (dispatch: Function) => {
  const { companyId } = props;

  const params = {
    active: true,
    companyId,
    $limit: -1,
  };

  try {
    const response = await axios.get<CompanyStockingParameter[]>(COMPANY_STOCKING_PARAMETER_URL, { params });
    dispatch(setCompanyStockingParameters(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export default stockingParameterSlice.reducer;
