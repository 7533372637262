import cx from 'classnames';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { THEME } from '../../../config/commons';
import { Store } from '../../../state/store.interfaces';
import { getCurrentTheme } from '../../../helpers/theme';
import { dateRanges } from '../../Sowings/Parameters/helpers';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { getParameterLabelByKey } from '../../Company/StockingParameters/helpers';

import { stockingParameterTypes } from './helpers';
import styles from './ParameterOptions.module.scss';
import * as parameterStateSlice from './parameterStateSlice';

export const ParameterOptions = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  const {
    stockingParameterGraphs,
    selectedDateOption,
    selectedParameterName,
  } = useSelector((state: Store) => state.parameterState);

  if (!stockingParameterGraphs.length) {
    return null;
  }

  return (
    <div
      className={styles.options}
    >
      <div className={styles.containerParameters}>
        <Space className={styles.parameters}>
          {stockingParameterTypes.map((parameter) => {
            if (parameter === 'biomass') {
              return null;
            }

            return (
              <div
                className={cx(styles.parameter, parameter === selectedParameterName ? isLightTheme ? styles.selectedParameterLight : styles.selectedParameterDark : '')}
                onClick={() => dispatch(parameterStateSlice.setSelectedParameterName(parameter))}
              >
                <LrvText theme={theme} text={t(`parameterStateGraph.parameters.${parameter}`)} />
              </div>
            );
          })}
        </Space>
      </div>

      <></>

      <div className={styles.containerParameters}>
        <Space className={styles.parameters}>
          {
            dateRanges.map((item) => {
              return (
                <div
                  className={cx(styles.dateOption, item === selectedDateOption ? isLightTheme ? styles.selectedDateOptionLight : styles.selectedDateOptionDark : '')}
                  onClick={() => {
                    dispatch(parameterStateSlice.setSelectedDateOption(item));
                  }}
                >
                  <LrvText theme={theme} text={getParameterLabelByKey(item)} />
                </div>
              );
            })
          }
        </Space>
      </div>
    </div>
  );
};
